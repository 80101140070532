import css from './GetStarted.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import H3title from '../../titles/H3title';
import TextGradient from '../../titles/TextGradient';
import SimpleButton from '../../buttons/SimpleButton';
import Ticker from 'framer-motion-ticker';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function GetStarted() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <section ref={ref} className={classNames(css.section, css.cta)}>
      <Container>
        <ShowAnimation ref={ref} order={1} direction={'-'}>
          <div className={css.ctaTextWrapper}>
            <div style={{textAlign: 'center'}}>
              <H3title isWhite={true}>{t('home.get-started.title_1')}<br/> <TextGradient>{t('home.get-started.title_2')}</TextGradient></H3title>
            </div>
            <p className={classNames(css.p, css.white, css.center)}>
              {t('home.get-started.description')}
            </p>
            <div style={{marginTop: '40px'}}>
              <SimpleButton text={t('buttons.get-started')} url={'/#/contact-us'} isSecondary={true}/>
            </div>
          </div>
        </ShowAnimation>
      </Container>
      <div className={css.logos}>
        <div className={css.logosWrap}>
          <div className={css.logosRow}>
            <Ticker duration={25}>
              <img loading={'lazy'} alt={'company-logo'}
                   className={classNames(css.img, css.companyLogo)} style={{height: '41px'}} src="/company_wk.svg"/>
              <img loading={'lazy'} alt={'company-logo'}
                   className={classNames(css.img, css.companyLogo)} src="/company_cox.svg"/>
              <img loading={'lazy'} alt={'company-logo'}
                   className={classNames(css.img, css.companyLogo)} src="/company_maersk.svg"/>
              <img loading={'lazy'} alt={'company-logo'}
                   className={classNames(css.img, css.companyLogo)} src="/company_swiss-re.svg"/>
            </Ticker>
          </div>
        </div>
      </div>
    </section>
  );
}