import css from './ContactUsInnerSection.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import ButtonsWrapper from '../../titles/wrapper/ButtonsWrapper';
import SimpleButton from '../../buttons/SimpleButton';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function ContactUsInnerSection({onClick}) {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <section ref={ref} className={classNames(css.section, css.innerBlock)}>
      <ShowAnimation ref={ref} direction={'-'} order={1}>
        <div className={classNames(css.sectionInnerBlock, css.imageBackground)}>
          <Container>
            <div className={css.sectionTitleWrapper}>
              <H2title isWhite={true}>{t('contact-us.inner-section.title_1')}<br/><TextGradient>{t('contact-us.inner-section.title_2')}</TextGradient></H2title>
              <p className={classNames(css.p, css.bannerHeaderDescription)}>
                {t('contact-us.inner-section.description')}
              </p>
            </div>
            <ButtonsWrapper>
              <SimpleButton text={t('buttons.get-started')} isSecondary={true} onClick={onClick}/>
            </ButtonsWrapper>
          </Container>
        </div>
      </ShowAnimation>
      <div className={css.darkBgAbsolute}/>
    </section>
  );
}