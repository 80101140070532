import css from './HomeBanner.module.scss';
import TopBackground from '../../backgrounds/TopBackground';
import SimpleButton from '../../buttons/SimpleButton';
import Description from '../../description/Description';
import H1title from '../../titles/H1title';
import Container from '../../container/Container';
import TitleWrapper from '../../titles/wrapper/TitleWrapper';
import TextGradient from '../../titles/TextGradient';
import ButtonsWrapper from '../../titles/wrapper/ButtonsWrapper';
import BoardText from '../../animated/BoardText';
import Cloud from '../../animated/Cloud';
import BannerWrapper from '../../animated/wrapper/BannerWrapper';
import {useRef} from 'react';
import {useScroll, motion, useTransform} from 'framer-motion';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function HomeBanner() {
  const {t} = useTranslation();
  const ref = useRef(null);
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ['start end', 'start start']
  });
  const cloudTop = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const boardTop = useTransform(scrollYProgress, [0, 1], [0, 100]);

  const titleWrapper = useRef(null);
  const buttonsWrapper = useRef(null);

  return (
    <>
      <section className={css.wrapper}>
        <TopBackground/>
        <Container>
          <TitleWrapper ref={titleWrapper}>
            <ShowAnimation ref={titleWrapper} direction={'v'} order={1}>
              <H1title isWhite={true}>
                <TextGradient>{t('home.top-title-1')}</TextGradient>{t('home.top-title-2')}
              </H1title>
            </ShowAnimation>
            <ShowAnimation ref={titleWrapper} direction={'v'} order={2}>
              <Description>
                {t('home.top-description')}
              </Description>
            </ShowAnimation>
          </TitleWrapper>
          <div ref={buttonsWrapper} style={{marginTop: '40px', zIndex: 20}}>
            <ShowAnimation ref={buttonsWrapper} direction={'v'} order={3}>
              <ButtonsWrapper>
                <SimpleButton text={t('buttons.get-started')} url={'/#/contact-us'}/>
                <SimpleButton text={t('buttons.our-solutions')} url={'/#/solutions'} isSecondary={true}/>
              </ButtonsWrapper>
            </ShowAnimation>
          </div>
          <div ref={ref} style={{zIndex: 20}}>
            <ShowAnimation ref={ref} direction={'v'} order={4}>
              <BannerWrapper>
                <motion.div style={{marginTop: boardTop}}>
                  <BoardText/>
                </motion.div>
                <img loading={'lazy'} src="/2005-01.svg" alt={'img1'} width={'100%'}/>
                <motion.div style={{marginTop: cloudTop}}>
                  <Cloud/>
                </motion.div>
              </BannerWrapper>
            </ShowAnimation>
          </div>
        </Container>
        <div className={css.bannerBackgroundGradient}/>
      </section>
    </>
  );
}