import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from './pages/HomePage';
import reportWebVitals from './reportWebVitals';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';
import OurValuesPage from './pages/OurValuesPage';
import ContactUsPage from './pages/ContactUsPage';
import {ThemeProvider} from '@material-tailwind/react';
import LanguageDetector from 'i18next-browser-languagedetector';
import {en, ru} from './i18n';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Solutions from './pages/Solutions';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

i18n.addResourceBundle('en','translation', en);
i18n.addResourceBundle('ru','translation', ru);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <HashRouter basename={'/'}>
        <Routes>
          <Route path={'/'} element={<HomePage/>}/>
          <Route path={'/our-values'} element={<OurValuesPage/>}/>
          <Route path={'/contact-us'} element={<ContactUsPage/>}/>
          <Route path={'/solutions'} element={<Solutions/>}/>
          <Route path={'*'} component={<Navigate to={'/'} replace/>}/>
        </Routes>
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
