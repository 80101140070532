import css from './Header.module.scss';
import SimpleButton from '../buttons/SimpleButton';
import {AnimatePresence, motion, useScroll, useTransform} from 'framer-motion';
import {Squash as Hamburger} from 'hamburger-react';
import {useCallback, useRef, useState} from 'react';
import {useClickAway} from 'react-use';
import classNames from 'classnames';
import MenuItem from './menu/MenuItem';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export default function Header() {
  const {t} = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  const {scrollY} = useScroll();
  const scrollRange = [0, 300];

  const backgroundColor = useTransform(
    scrollY,
    scrollRange,
    ['rgba(0, 0, 0, 0)', 'rgba(38, 12, 66, 1)']
  )

  const onChangeLang = useCallback(() => {
    i18n.changeLanguage(i18n.language === 'en' ? 'ru' : 'en');
  }, []);

  const routes = [
    {
      title: t('header.main'),
      href: '/'
    }, {
      title: t('header.our-values'),
      href: '/#/our-values'
    }, {
      title: t('header.solutions'),
      href: '/#/solutions'
    }, {
      title: t('buttons.contact-now'),
      href: '/#/contact-us'
    }, {
      title: i18n.language === 'en' ? '🇺🇸' : '🇷🇺',
      onClick: onChangeLang
    }
  ];

  return (
    <motion.div
      className={css.header}
      style={{
        backgroundColor
      }}
      ref={ref}
    >
      <div className={css.navContainer}>
        <div className={css.navMenuWrapper}>
          <a className={css.brand} href={'/'}>
            <img loading={'lazy'} alt={'header-logo'} className={css.headerLogo} src="/footer_logo_svg.svg" />
          </a>

          <div className={css.desktopItems}>
            <MenuItem url={'/'} title={t('header.main')}/>
            <MenuItem url={'/#/our-values'} title={t('header.our-values')}/>
            <MenuItem url={'/#/solutions'} title={t('header.solutions')}/>
            <MenuItem onClick={onChangeLang} title={i18n.language === 'en' ? '🇺🇸' : '🇷🇺'}/>
          </div>

          <div className={css.desktopItems}>
            <SimpleButton text={t('buttons.contact-now')} url={'/#/contact-us'}/>
          </div>

          <div className={css.mobileMenuButton}>
            <Hamburger toggled={isOpen} size={20} toggle={setOpen} color={'var(--white)'}/>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}
            style={{
              textAlign: 'start',
              width: '100%'
            }}
          >
            <div style={{borderTop: '1px var(--light-gray) solid'}}>
              {routes.map((route, idx) => {
                return (
                  <motion.div
                    initial={{scale: 0, opacity: 0}}
                    animate={{scale: 1, opacity: 1}}
                    transition={{
                      type: 'spring',
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                  >
                    <div style={{padding: '0 15px', backgroundColor: 'rgb(38, 12, 66)'}}>
                      <a className={classNames(css.a, css.navLink, css.inlineBlock)}
                         href={route.href}
                         onClick={() => {
                           setOpen((prev) => !prev);
                           route.onClick && route.onClick();
                         }}>
                        <div className={css.navWrapInner}>
                          <p className={classNames(css.p, css.navItemTitle, css.white)}>{route.title}</p>
                        </div>
                      </a>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};