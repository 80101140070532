import {useEffect, useRef, useState} from 'react';
import css from './World.module.scss';
import world from './countries-110m-new.json';
import {geoNaturalEarth1, geoPath, range, select, timer} from 'd3';
import * as topojson from 'topojson-client';
import Container from '../container/Container';
import TitleWrapper from '../titles/wrapper/TitleWrapper';
import ShowAnimation from '../helpers/ShowAnimation';
import H2title from '../titles/H2title';
import {useTranslation} from 'react-i18next';
import WhiteSection from '../sections/whiteSection/WhiteSection';
import Description from '../description/Description';

export default function World() {
  const {t} = useTranslation();
  const ref = useRef();
  const [countryName, setCountryName] = useState('');

  const diameter = 320;
  const velocity = 0.003;

  const projection = geoNaturalEarth1().fitExtent([[0.5, 0.5], [diameter - 0.5, diameter / 2 - 0.5]], {type: 'Sphere'});

  useEffect(() => {
    const svg = select('#contact-us-world-map')
      .selectAll('svg')
      .data(range(1))
      .enter()
      .append('svg')
      .attr('viewBox', `0 0 ${diameter} ${diameter / 2}`);

    const path = geoPath().projection(projection);
    const countries = topojson.feature(world, world.objects.countries).features;

    svg
      .append('g')
      .selectAll('path')
      .data(countries)
      .enter()
      .append('path')
      .attr('fill', '#ccc')
      .attr('d', path)
      .style('stroke', '#fff')
      .style('stroke-width', .1)
      .on('mousemove', function () {
        const data = select(this).datum();
        if ('not-supported' === data.id) {
          setCountryName(t('contact-us.world.not-supported'));
        } else {
          setCountryName(data?.properties?.name || '');
        }
      })
      .html(`<style>path:hover{fill: var(--primary-violet);}</style>`)

    timer((elapsed) => {
      projection.rotate([(velocity * elapsed) % 360, 0, 0]);
      svg.selectAll('path').attr('d', path);
    });
  }, [countryName, projection, t]);

  return (
    <div ref={ref}>
      <WhiteSection cn={css.pt0}>
        <Container>
          <TitleWrapper>
            <ShowAnimation ref={ref} order={1} direction={'v'}>
              <H2title>{t('contact-us.world.title')}</H2title>
            </ShowAnimation>
            <ShowAnimation ref={ref} order={2} direction={'v'}>
              <Description isDark={true}>
                {
                  countryName.length === 0
                    ? <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}></span>
                    : countryName
                }
              </Description>
            </ShowAnimation>
          </TitleWrapper>
          <ShowAnimation ref={ref} order={3} direction={'v'} fullWidth={true}>
            <div className={css.worldMapWrapper} style={{display: 'flex', justifyContent: 'center'}}
                 id={'contact-us-world-map'}/>
          </ShowAnimation>
        </Container>
      </WhiteSection>
    </div>
  );
}