import css from './Services.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function Services() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <div ref={ref} className={classNames(css.section, css.darkBgAbout)}>
      <Container>
        <div className={css.servicesAboutImage}>
          <ShowAnimation ref={ref} direction={'hr'} order={1}>
            <img alt={'service'} loading={'lazy'} className={css.rightImage} src="/01.png"/>
            <div className={css.imageBlur}/>
          </ShowAnimation>
        </div>
        <div className={css.contentWrapperLeft}>
          <ShowAnimation ref={ref} direction={'v'} order={1}>
            <H2title isWhite={true}>
              {t('home.services.title_1')}<br/><TextGradient>{t('home.services.title_2')}</TextGradient>
            </H2title>
          </ShowAnimation>
          <ShowAnimation ref={ref} direction={'v'} order={2}>
            <p className={classNames(css.p, css.paragraphLight, css.mt25)}>
              {t('home.services.description_1')}
            </p>
          </ShowAnimation>
          <ShowAnimation ref={ref} direction={'v'} order={3}>
            <p className={classNames(css.p, css.paragraphLight)}>
              {t('home.services.description_2')}
            </p>
          </ShowAnimation>
        </div>
      </Container>
    </div>
  );
}