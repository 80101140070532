import css from './SolutionsListSection.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import TitleWrapper from '../../titles/wrapper/TitleWrapper';
import H1title from '../../titles/H1title';
import Description from '../../description/Description';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import SolutionItem from '../../banners/solutionsIntro/solutions/SolutionItem';
import {useTranslation} from 'react-i18next';

export default function SolutionsListSection() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <section ref={ref} className={classNames(css.section, css.serviceListBanner)}>
      <img loading={'lazy'} alt={'solutions-list-bg'} className={classNames(css.img, css.bannerImage)}
           src={'/TopBackground.webp'}/>
      <div className={css.bannerBackgroundGradient}/>
      <Container>
        <TitleWrapper>
          <ShowAnimation ref={ref} direction={'v'} order={1}>
            <H1title isWhite={true}>{t('solutions.title')}</H1title>
          </ShowAnimation>
          <ShowAnimation ref={ref} direction={'v'} order={2}>
            <Description>
              {t('solutions.description')}
            </Description>
          </ShowAnimation>
        </TitleWrapper>

        <ShowAnimation ref={ref} direction={'v'} order={3}>
        <div className={classNames(css.solutionsCollectionListWrapper, css.mt40)}>
          <div className={classNames(css.solutionsCollectionList, css.solutionGridItems)}>
            <SolutionItem url={'/solution_cloud.svg'} title={t('solutions.solution-item_1.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_1.description')}/>
            <SolutionItem url={'/solution_microservice.png'} title={t('solutions.solution-item_2.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_2.description')}/>
            <SolutionItem url={'/solution_devops.svg'} title={t('solutions.solution-item_3.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_3.description')}/>
            <SolutionItem url={'/solution_data.png'} title={t('solutions.solution-item_4.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_4.description')}/>
            <SolutionItem url={'/solution_api.webp'} title={t('solutions.solution-item_5.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_5.description')}/>
            <SolutionItem url={'/solution_container_2.webp'} title={t('solutions.solution-item_6.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_6.description')}/>
            <SolutionItem url={'/solution_security.webp'} title={t('solutions.solution-item_7.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_7.description')}/>
            <SolutionItem url={'/solution_legacy.svg'} title={t('solutions.solution-item_8.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_8.description')}/>
            <SolutionItem url={'/solution_event-driven.png'} title={t('solutions.solution-item_9.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_9.description')}/>
            <div/>
            <SolutionItem url={'/solution_agile.png'} title={t('solutions.solution-item_10.title')}
                          cn={css.width100p}
                          description={t('solutions.solution-item_10.description')}/>
          </div>
        </div>
        </ShowAnimation>
      </Container>
    </section>
  );
}