import css from './WhyTrust.module.scss';
import Container from '../../container/Container';
import TitleWrapper from '../../titles/wrapper/TitleWrapper';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import classNames from 'classnames';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default function WhyTrust() {
  const {t} = useTranslation();
  const sectionRef = useRef();
  const gridOfTrustRef = useRef();

  return (
    <section ref={sectionRef} className={css.section}>
      <Container>
        <ShowAnimation ref={sectionRef} direction={'v'} order={1}>
          <TitleWrapper className={css.sectionTitleWrapper}>
            <H2title>{t('home.why-trust.title_1')}<br/><TextGradient>{t('home.why-trust.title_2')}</TextGradient></H2title>
          </TitleWrapper>
        </ShowAnimation>
        <div ref={gridOfTrustRef} className={css.gridOfTrust}>
          <ShowAnimation ref={gridOfTrustRef} order={2} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_1.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_1.description')}
              </p>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={gridOfTrustRef} order={2} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_2.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_2.description')}
              </p>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={gridOfTrustRef} order={2} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_3.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_3.description')}
              </p>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={gridOfTrustRef} order={4} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_4.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_4.description')}
              </p>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={gridOfTrustRef} order={4} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_5.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_5.description')}
              </p>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={gridOfTrustRef} order={4} direction={'v'}>
            <div className={css.trustInnerWrap}>
              <div className={css.bigNumber}>
                {t('home.why-trust.why_6.title')}
              </div>
              <p className={classNames(css.textCenter, css.dark)}>
                {t('home.why-trust.why_6.description')}
              </p>
            </div>
          </ShowAnimation>
        </div>
      </Container>
    </section>
  );
}