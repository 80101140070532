import css from './TitleWrapper.module.scss';
import classNames from 'classnames';
import {forwardRef} from 'react';

const TitleWrapper = forwardRef(({children, style, className}, ref) => {
  return (
    <div ref={ref} className={classNames(css.titleWrapCenter, className)} style={style}>
      {children}
    </div>
  );
});

export default TitleWrapper;