import css from './HomeInnerBlock.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import H2title from '../../titles/H2title';
import Description from '../../description/Description';
import TitleWrapper from '../../titles/wrapper/TitleWrapper';
import TextGradient from '../../titles/TextGradient';
import SimpleButton from '../../buttons/SimpleButton';
import ButtonsWrapper from '../../titles/wrapper/ButtonsWrapper';
import InnerBlockGradient from '../../innerBlockGradient/InnerBlockGradient';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function HomeInnerBlock() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <section ref={ref} className={classNames(css.section, css.innerBlock)}>
      <InnerBlockGradient/>
      <ShowAnimation ref={ref} order={1} direction={'-'}>
        <div style={{zIndex: 20}} className={classNames(css.sectionInnerBlock, css.imageBackground)}>
          <Container>
            <TitleWrapper style={{marginBottom: '40px'}}>
              <H2title isWhite={true}>
                {t('home.inner-block.title_1')}<TextGradient>{t('home.inner-block.title_2')}</TextGradient>
              </H2title>
              <Description>
                {t('home.inner-block.description')}
              </Description>
            </TitleWrapper>
            <ButtonsWrapper>
              <SimpleButton text={t('buttons.get-started')} url={'/#/contact-us'}/>
              <SimpleButton text={t('buttons.our-solutions')} url={'/#/solutions'} isSecondary={true}/>
            </ButtonsWrapper>
          </Container>
        </div>
      </ShowAnimation>
      <div className={css.darkBgAbsolute}/>
    </section>
  );
}