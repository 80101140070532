import Header from '../header/Header';
import HomeBanner from '../banners/home/HomeBanner';
import MainFocus from '../banners/focus/MainFocus';
import HomeInnerBlock from '../banners/homeInnerBlock/HomeInnerBlock';
import WhyTrust from '../banners/whyTrust/WhyTrust';
import Services from '../banners/services/Services';
import SolutionsIntro from '../banners/solutionsIntro/SolutionsIntro';
import Integrations from '../banners/integrations/Integrations';
import GetStarted from '../banners/getStarted/GetStarted';
import Footer from '../banners/footer/Footer';
import ScrollToTop from '../helpers/ScrollTop';

function HomePage() {
  return (
    <>
      <ScrollToTop/>
      <Header/>
      <HomeBanner/>
      <MainFocus/>
      <HomeInnerBlock/>
      <WhyTrust/>
      <Services/>
      <SolutionsIntro/>
      <Integrations/>
      {/*<Feedback/>*/}
      <GetStarted/>
      <Footer/>
    </>
  );
}

export default HomePage;
