import SolutionsListSection from '../sections/solutionsListSection/SolutionsListSection';
import Header from '../header/Header';
import Footer from '../banners/footer/Footer';
import WhiteSection from '../sections/whiteSection/WhiteSection';
import Container from '../container/Container';
import SolutionsFAQ from '../banners/solutionsFAQ/SolutionsFAQ';
import ScrollToTop from '../helpers/ScrollTop';

export default function Solutions() {
  return (
    <>
      <ScrollToTop/>
      <Header/>
      <SolutionsListSection/>
      <WhiteSection>
        <Container>
          <SolutionsFAQ/>
        </Container>
      </WhiteSection>
      <Footer/>
    </>
  );
}