import css from './Feature.module.scss';
import H4title from '../../../titles/H4title';
import classNames from 'classnames';

export default function Feature({image, title, description, linkText, last, url}) {
  return (
    <div className={classNames(css.featuresWrap, {'last': last})}>
      <img alt={'alt'} src={image} loading={'lazy'} className={css.featuresImage}/>
      <H4title isWhite={true}>{title}</H4title>
      <p className={css.featureDescription}>{description}</p>
      <div className={classNames(css.linkWrapper, css.center)}>
        <a className={classNames(css.a, css.linkWithIcon)} href={url}>{linkText}</a>
        <span className={css.linkArrow}>&rarr;</span>
      </div>
    </div>
  );
}