import css from './StatisticItem.module.scss';
import H3title from '../../../titles/H3title';
import TextGradient from '../../../titles/TextGradient';

export default function StatisticItem({title, description}) {
  return (
    <div className={css.statisticItem}>
      <H3title><TextGradient>{title}</TextGradient></H3title>
      <p style={{marginTop: '5px'}} className={css.p}>{description}</p>
    </div>
  );
}