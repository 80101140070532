import css from './SolutionsFAQ.module.scss';
import classNames from 'classnames';
import AskItem from '../frequentlyAsk/askItem/AskItem';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function SolutionsFAQ() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <div ref={ref} className={css.gridTwoSections}>
      <ShowAnimation ref={ref} order={1} direction={'hl'}>
        <img alt={'solutions-faq'} className={classNames(css.img, css.borderRadius)} src={'/solution_faq_left.jpeg'}/>
      </ShowAnimation>
      <ShowAnimation ref={ref} order={1} direction={'hr'}>
        <div className={css.accordionWrapperOneColumn}>
          <div className={css.accordionWrapOneColumn}>
            <AskItem title={t('solutions.faq.item_1.title')}
                     description={t('solutions.faq.item_1.description')}/>
            <AskItem title={t('solutions.faq.item_2.title')}
                     description={t('solutions.faq.item_2.description')}/>
            <AskItem title={t('solutions.faq.item_3.title')}
                     description={t('solutions.faq.item_3.description')}/>
            <AskItem title={t('solutions.faq.item_4.title')}
                     description={t('solutions.faq.item_4.description')}/>
            <AskItem title={t('solutions.faq.item_5.title')}
                     description={t('solutions.faq.item_5.description')}/>
          </div>
        </div>
      </ShowAnimation>
    </div>
  );
}