import css from './SolutionsIntro.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import TitleWrapper from '../../titles/wrapper/TitleWrapper';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import Description from '../../description/Description';
import SolutionItem from './solutions/SolutionItem';
import SimpleButton from '../../buttons/SimpleButton';
import BoardText from '../../animated/BoardText';
import Cloud from '../../animated/Cloud';
import BannerWrapper from '../../animated/wrapper/BannerWrapper';
import {useRef} from 'react';
import {useScroll, useTransform, motion} from 'framer-motion';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function SolutionsIntro() {
  const {t} = useTranslation();
  const ref = useRef(null);
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ['start end', 'start start']
  });
  const cloudTop = useTransform(scrollYProgress, [0, 1], [100, 0]);
  const boardTop = useTransform(scrollYProgress, [0, 1], [0, 100]);

  const introRef = useRef();
  const listRef = useRef();
  const buttonRef = useRef();

  return (
    <div ref={introRef} className={classNames(css.section, css.gradientBg)}>
      <Container>
        <TitleWrapper className={css.mb40}>
          <ShowAnimation ref={introRef} direction={'v'} order={1}>
            <H2title isWhite={true}>
              {t('home.solutions-intro.title_1')}<TextGradient>{t('home.solutions-intro.title_2')}</TextGradient>
            </H2title>
          </ShowAnimation>
          <ShowAnimation ref={introRef} direction={'v'} order={2}>
            <Description>
              {t('home.solutions-intro.description')}
            </Description>
          </ShowAnimation>
        </TitleWrapper>

        <div ref={listRef} className={css.solutionsWrapper}>
          <ShowAnimation ref={listRef} direction={'v'} order={2}>
            <div className={css.solutionsCollectionListWrapper}>
              <div className={css.solutionsCollectionList}>
                <SolutionItem url={'/solution_assessment.svg'}
                              title={t('home.solutions-intro.solution-item_1.title')}
                              description={t('home.solutions-intro.solution-item_1.description')}/>
                <SolutionItem url={'/solution_design.png'}
                              title={t('home.solutions-intro.solution-item_2.title')}
                              description={t('home.solutions-intro.solution-item_2.description')}/>
                <SolutionItem url={'/solution_devops_home.png'}
                              title={t('home.solutions-intro.solution-item_3.title')}
                              description={t('home.solutions-intro.solution-item_3.description')}/>
                <SolutionItem url={'/solution_consulting.svg'}
                              title={t('home.solutions-intro.solution-item_4.title')}
                              description={t('home.solutions-intro.solution-item_4.description')}/>
                <SolutionItem url={'/solution_development.png'}
                              title={t('home.solutions-intro.solution-item_5.title')}
                              description={t('home.solutions-intro.solution-item_5.description')}/>
                <SolutionItem url={'/solution_webinars.png'}
                              title={t('home.solutions-intro.solution-item_6.title')}
                              description={t('home.solutions-intro.solution-item_6.description')}/>
                <SolutionItem url={'/solution_optimization.svg'}
                              title={t('home.solutions-intro.solution-item_7.title')}
                              description={t('home.solutions-intro.solution-item_7.description')}/>
                <SolutionItem url={'/solution_compliance.png'}
                              title={t('home.solutions-intro.solution-item_8.title')}
                              description={t('home.solutions-intro.solution-item_8.description')}/>
                <SolutionItem url={'/solution_stakeholder.png'}
                              title={t('home.solutions-intro.solution-item_9.title')}
                              description={t('home.solutions-intro.solution-item_9.description')}/>
                <SolutionItem url={'/solution_cloud.svg'}
                              title={t('home.solutions-intro.solution-item_10.title')}
                              description={t('home.solutions-intro.solution-item_10.description')}/>
              </div>
            </div>
          </ShowAnimation>
        </div>

        <div ref={buttonRef} className={css.mt40}>
          <ShowAnimation ref={buttonRef} direction={'v'} order={1}>
            <SimpleButton isSecondary={true} url={'/#/contact-us'} text={t('buttons.get-started')}/>
          </ShowAnimation>
        </div>

        <div className={classNames(css.relative, css.mt40)} ref={ref}>
          <ShowAnimation ref={ref} direction={'v'} order={1}>
            <Container>
              <BannerWrapper>
                <motion.div style={{marginTop: boardTop}}>
                  <BoardText/>
                </motion.div>
                <img loading={'lazy'} alt={'solutions-icon'} src="/security_img.webp"
                     className={css.bannerImageLaptop}/>
                <motion.div style={{marginTop: cloudTop}}>
                  <Cloud/>
                </motion.div>
              </BannerWrapper>
            </Container>
          </ShowAnimation>
        </div>
      </Container>
    </div>
  );
}