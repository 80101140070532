import css from './Integrations.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import H2title from '../../titles/H2title';
import Description from '../../description/Description';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function Integrations() {
  const {t} = useTranslation();
  const sectionRef = useRef();
  const iconsRef = useRef();

  return (
    <section ref={sectionRef} className={classNames(css.section, css.backgroundImage)}>
      <Container>
        <ShowAnimation ref={sectionRef} order={1} direction={'v'}>
          <div style={{textAlign: 'center'}}>
            <H2title>{t('home.integrates.title_1')}<br/>{t('home.integrates.title_2')}</H2title>
          </div>
        </ShowAnimation>
        <ShowAnimation ref={sectionRef} order={2} direction={'v'}>
          <Description cn={css.maxWidthInit} isDark={true}>
            {t('home.integrates.description')}
          </Description>
        </ShowAnimation>
      </Container>
      <div ref={iconsRef} className={css.integrationImagesWrapper}>
        <ShowAnimation ref={iconsRef} order={1} direction={'hl'}>
          <img loading={'lazy'} alt={'integration'} src={'/integration_gitlab.svg'}
               className={classNames(css.img, css.integrationServiceImage, css.mt55)}/>
        </ShowAnimation>
        <ShowAnimation ref={iconsRef} order={2} direction={'hl'}>
          <img loading={'lazy'} alt={'integration'} src={'/integration_bamboo.svg'}
               className={classNames(css.img, css.integrationServiceImage, css.mt135)}/>
        </ShowAnimation>
        <ShowAnimation ref={iconsRef} order={3} direction={'hl'}>
          <img loading={'lazy'} alt={'integration'} src={'/integration_kubernetes.svg'}
               className={classNames(css.img, css.integrationServiceImage, css.mt30)}/>
        </ShowAnimation>
        <ShowAnimation ref={iconsRef} order={4} direction={'hl'}>
          <img loading={'lazy'} alt={'integration'} src={'/integration_java.svg'}
               className={classNames(css.img, css.integrationServiceImage, css.mt90)}/>
        </ShowAnimation>
        <ShowAnimation ref={iconsRef} order={5} direction={'hl'}>
          <img loading={'lazy'} alt={'integration'} src={'/integration_docker.svg'}
               className={classNames(css.img, css.integrationServiceImage)}/>
        </ShowAnimation>
      </div>
    </section>
  );
}