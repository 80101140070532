import classNames from 'classnames';
import css from '../Header.module.scss';
import {motion} from 'framer-motion';
import {useState} from 'react';

export default function MenuItem({title, url, onClick}) {
  const [isHovered, setHovered] = useState(false);

  return (
    <a className={classNames(css.a, css.navLink, css.inlineBlock)}
       href={url}
       onClick={onClick}
       style={{cursor: 'pointer'}}
       onMouseEnter={() => setHovered(true)}
       onMouseLeave={() => setHovered(false)}>
      <div className={css.navWrapInner}>
        <p className={classNames(css.p, css.navItemTitle, css.white)}>{title}</p>
        <motion.div
          initial={{width: 0}}
          animate={{width: isHovered ? '100%' : 0}}
          className={css.navHoverLine}
          style={{height: '2px'}}/>
      </div>
    </a>
  );
}