import css from './GetInTouch.module.scss';
import classNames from 'classnames';
import KitesurfingOutlinedIcon from '@mui/icons-material/KitesurfingOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import Alert from '@mui/material/Alert';
import H2title from '../../titles/H2title';
import Container from '../../container/Container';
import WhiteSection from '../../sections/whiteSection/WhiteSection';
import H5title from '../../titles/H5title';
import {useCallback, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';
import SubmitButton from '../../buttons/SubmitButton';

export default function GetInTouch() {
  const {t} = useTranslation();
  const ref = useRef();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [alignment, setAlignment] = useState('blog');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [textarea, setTextarea] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const setNameOnChange = useCallback((event) => {
    setName(event.target.value.trim());
  }, []);

  const setCompanyOnChange = useCallback((event) => {
    setCompany(event.target.value.trim());
  }, []);

  const setEmailOnChange = useCallback((event) => {
    setEmail(event.target.value.trim());
  }, []);

  const setPhoneOnChange = useCallback((event) => {
    setPhone(event.target.value.trim());
  }, []);

  const setTextareaOnChange = useCallback((event) => {
    setTextarea(event.target.value.trim());
  }, []);

  const send = useCallback(async () => {
    if (window.grecaptcha) {
      setSubmitDisabled(true);

      const token = await window.grecaptcha.execute('6Lcb7QMeAAAAAJ0-_t58i6fAdnSbY-5K9YHPv7yW');
      try {
        const request = await fetch('https://surecom.in/api/capcha/', {
          method: 'POST',
          body: JSON.stringify({
            token,
            name, email,
            message: `
          Alignment: ${alignment},<br/><br/>
          Company: ${company},<br/>
          Phone: ${phone},<br/>
          <br/><br/>
          Message: ${textarea}
        `
          })
        });

        const result = await request.json();

        if (result.status === 'success') {
          setName('');
          setCompany('');
          setEmail('');
          setPhone('')
          setTextarea('');
          setSuccess(true);
          setError(false);
        } else {
          setSuccess(false);
          setError(true);
        }
      } catch (e) {
        setSubmitDisabled(false);
      } finally {
        setSubmitDisabled(false);
      }
    }
  }, [name, company, email, phone, alignment, textarea]);

  return (
    <WhiteSection>
      <Container>
        <div ref={ref} className={classNames(css.layoutFlex, css.getInTouchSectionTitleWrapper)}>
          <div className={css.formContactLeftSection}>
            <ShowAnimation ref={ref} direction={'hl'} order={1}>
              <div className={css.formTitleContentInner}>
                <H2title isWhite={true}>{t('contact-us.get-in-touch.title')}</H2title>
                <p className={classNames(css.p, css.white)}>
                  {t('contact-us.get-in-touch.description')}
                </p>
              </div>
            </ShowAnimation>
          </div>
          <ShowAnimation ref={ref} direction={'hr'} order={1}>
            <div className={css.contactFormWrapper}>
              <div className={classNames(css.contactUsForm, css.form)}>
                <form className={css.formInner} style={{display: success || error ? 'none' : 'block'}}>
                  <H5title cn={classNames(css.mb20)}>{t('contact-us.form.title-form')}</H5title>
                  <div className={css.inputFieldsGrid}>
                    <input className={classNames(css.input, css.transpatentGray, css.mInput)} maxLength={256}
                           name={'name'} onChange={setNameOnChange}
                           placeholder={t('contact-us.form.full-name_ph')} type={'text'} required={true}/>
                    <input className={classNames(css.input, css.transpatentGray, css.mInput)} maxLength={256}
                           name={'company'} onChange={setCompanyOnChange}
                           placeholder={t('contact-us.form.company-name_ph')} type={'text'} required={true}/>
                    <input className={classNames(css.input, css.transpatentGray, css.mInput)} maxLength={256}
                           name={'email'} onChange={setEmailOnChange}
                           placeholder={t('contact-us.form.your-email_ph')} type={'email'} required={true}/>
                    <input className={classNames(css.input, css.transpatentGray, css.mInput)} maxLength={256}
                           name={'phone'} onChange={setPhoneOnChange}
                           placeholder={t('contact-us.form.your-phone-number_ph')} type={'tel'} required={true}/>
                  </div>
                  <div>
                    <H5title cn={classNames(css.mb20)}>{t('contact-us.form.title-topic')}</H5title>
                    <div className={css.checkboxWrap}>
                      <motion.label whileTap={{scale: .9}}
                                    whileHover={{scale: 1.1}}
                                    onClick={e => handleChange(e, 'blog')}
                                    className={classNames(css.label, css.radioButtonField, css.radio)}>
                        <div
                          className={classNames(css.formRadioCustom, css.checkboxCenter, css.checkbox, css.radioInput, {[css.checked]: alignment === 'blog'})}>
                          <div>{t('contact-us.form.topic.blog')}</div>
                          <KitesurfingOutlinedIcon/>
                        </div>
                      </motion.label>
                      <motion.label whileTap={{scale: .9}}
                                    whileHover={{scale: 1.1}}
                                    onClick={e => handleChange(e, 'job')}
                                    className={classNames(css.label, css.radioButtonField, css.radio)}>
                        <div
                          className={classNames(css.formRadioCustom, css.checkboxCenter, css.checkbox, css.radioInput, {[css.checked]: alignment === 'job'})}>
                          <div>{t('contact-us.form.topic.job')}</div>
                          <EngineeringOutlinedIcon/>
                        </div>
                      </motion.label>
                      <motion.label whileTap={{scale: .9}}
                                    whileHover={{scale: 1.1}}
                                    onClick={e => handleChange(e, 'solutions')}
                                    className={classNames(css.label, css.radioButtonField, css.radio)}>
                        <div
                          className={classNames(css.formRadioCustom, css.checkboxCenter, css.checkbox, css.radioInput, {[css.checked]: alignment === 'solutions'})}>
                          <div>{t('contact-us.form.topic.solutions')}</div>
                          <EmojiObjectsOutlinedIcon/>
                        </div>
                      </motion.label>
                      <motion.label whileTap={{scale: .9}}
                                    whileHover={{scale: 1.1}}
                                    onClick={e => handleChange(e, 'other')}
                                    className={classNames(css.label, css.radioButtonField, css.radio)}>
                        <div
                          className={classNames(css.formRadioCustom, css.checkboxCenter, css.checkbox, css.radioInput, {[css.checked]: alignment === 'other'})}>
                          <div>{t('contact-us.form.topic.other')}</div>
                          <SmsOutlinedIcon/>
                        </div>
                      </motion.label>
                    </div>
                  </div>
                  <div className={classNames(css.textareaWrapper, css.contactUs)}>
                    <H5title cn={classNames(css.mb20)}>{t('contact-us.form.textarea-title')}</H5title>
                    <textarea onChange={setTextareaOnChange}
                              className={classNames(css.textarea, css.input, css.transpatentTextareaGray, css.mInput)}/>
                  </div>
                  <div style={{marginTop: '40px'}}>
                    <SubmitButton disabled={submitDisabled} text={t('buttons.submit')} onClick={send}/>
                  </div>
                </form>
                <div style={{display: success ? 'block' : 'none'}}>
                  <Alert severity={'success'}>
                    {t('contact-us.form.success')}
                  </Alert>
                </div>
                <div style={{display: error ? 'block' : 'none'}}>
                  <Alert severity={'error'}>
                    {t('contact-us.form.error')}
                  </Alert>
                </div>
              </div>
            </div>
          </ShowAnimation>
        </div>
      </Container>
    </WhiteSection>
  );
}