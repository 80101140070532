import css from './TopBackground.module.scss';

export default function TopBackground() {
  return (
    <img
      src='/TopBackground.webp'
      loading='lazy' sizes='(max-width: 1441px) 100vw, (max-width: 1919px) 1441px, 100vw'
      srcSet='/TopBackground-500.webp 500w, /TopBackground-800.webp 800w, /TopBackground.webp 1441w'
      alt={'top-banner'} className={css.image}/>
  );
}