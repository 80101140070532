import GetStarted from '../banners/getStarted/GetStarted';
import Footer from '../banners/footer/Footer';
import Header from '../header/Header';
import OurValuesList from '../banners/ourValuesList/OurValuesList';
import ValuableServices from '../banners/valuableServices/ValuableServices';
import BannerTopSection from '../bannerTopSection/BannerTopSection';
import Container from '../container/Container';
import TitleWrapper from '../titles/wrapper/TitleWrapper';
import H1title from '../titles/H1title';
import Description from '../description/Description';
import ScrollToTop from '../helpers/ScrollTop';
import {useRef} from 'react';
import ShowAnimation from '../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function OurValuesPage() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <>
      <ScrollToTop/>
      <Header/>
      <BannerTopSection>
        <Container>
          <TitleWrapper ref={ref}>
            <ShowAnimation ref={ref} order={1} direction={'v'}>
              <H1title isWhite={true}>{t('our-values.title')}</H1title>
            </ShowAnimation>
            <ShowAnimation ref={ref} order={2} direction={'v'}>
              <Description>
                {t('our-values.description')}
              </Description>
            </ShowAnimation>
          </TitleWrapper>
        </Container>
      </BannerTopSection>
      <OurValuesList/>
      <ValuableServices/>
      <GetStarted/>
      <Footer/>
    </>
  );
}