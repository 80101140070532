import css from './OurValuesList.module.scss';
import classNames from 'classnames';
import Container from '../../container/Container';
import InnerBlockGradient from '../../innerBlockGradient/InnerBlockGradient';
import ValueItem from './valueItem/ValueItem';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function OurValuesList() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <section ref={ref} className={classNames(css.section, css.sectionGradient)}>
      <Container>
        <ShowAnimation ref={ref} order={3} direction={'v'}>
          <div className={classNames(css.layoutGrid, css.valuesListCounterGrid)}>
            <ValueItem url={'/values_innovation.svg'} title={t('our-values.item_1.title')}
                       description={t('our-values.item_1.description')}/>
            <ValueItem url={'/values_passion.svg'} title={t('our-values.item_2.title')}
                       description={t('our-values.item_2.description')}/>
            <ValueItem url={'/values_teamwork.svg'} title={t('our-values.item_3.title')}
                       description={t('our-values.item_3.description')}/>
            <ValueItem url={'/values_commitment.svg'} title={t('our-values.item_4.title')}
                       description={t('our-values.item_4.description')}/>
            <ValueItem url={'/values_result-oriented.svg'} title={t('our-values.item_5.title')}
                       description={t('our-values.item_5.description')}/>
            <ValueItem url={'/values_sustainability.svg'} title={t('our-values.item_6.title')}
                       description={t('our-values.item_6.description')}/>
          </div>
        </ShowAnimation>
      </Container>
      <InnerBlockGradient isValue={true}/>
    </section>
  );
}