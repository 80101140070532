import css from './FrequentlyAsk.module.scss';
import WhiteSection from '../../sections/whiteSection/WhiteSection';
import Container from '../../container/Container';
import H2title from '../../titles/H2title';
import TextGradient from '../../titles/TextGradient';
import AskItem from './askItem/AskItem';
import classNames from 'classnames';
import {useRef} from 'react';
import ShowAnimation from '../../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';

export default function FrequentlyAsk() {
  const {t} = useTranslation();
  const ref = useRef();

  return (
    <div ref={ref}>
      <WhiteSection cn={css.pt0}>
        <Container>
          <ShowAnimation ref={ref} order={1} direction={'v'}>
            <div style={{marginBottom: '30px', marginRight: 'auto'}}>
              <H2title>{t('contact-us.faq.title_1')}<TextGradient>{t('contact-us.faq.title_2')}</TextGradient></H2title>
            </div>
          </ShowAnimation>
          <ShowAnimation ref={ref} order={2} direction={'v'}>
            <div className={css.accordionWrapper}>
              <div className={css.accordionWrap}>
                <AskItem title={t('contact-us.faq.question-left_1.title')}
                         description={t('contact-us.faq.question-left_1.description')}/>
                <AskItem title={t('contact-us.faq.question-left_2.title')}
                         description={t('contact-us.faq.question-left_2.description')}/>
                <AskItem title={t('contact-us.faq.question-left_3.title')}
                         description={t('contact-us.faq.question-left_3.description')}/>
                <AskItem title={t('contact-us.faq.question-left_4.title')}
                         description={t('contact-us.faq.question-left_4.description')}/>
                <AskItem title={t('contact-us.faq.question-left_5.title')}
                         description={t('contact-us.faq.question-left_5.description')}/>
                <AskItem title={t('contact-us.faq.question-left_6.title')}
                         description={t('contact-us.faq.question-left_6.description')}/>
              </div>
              <div className={classNames(css.accordionWrap, css.lastChild)}>
                <AskItem title={t('contact-us.faq.question-right_1.title')}
                         description={t('contact-us.faq.question-right_1.description')}/>
                <AskItem title={t('contact-us.faq.question-right_2.title')}
                         description={t('contact-us.faq.question-right_2.description')}/>
                <AskItem title={t('contact-us.faq.question-right_3.title')}
                         description={t('contact-us.faq.question-right_3.description')}/>
                <AskItem title={t('contact-us.faq.question-right_4.title')}
                         description={t('contact-us.faq.question-right_4.description')}/>
                <AskItem title={t('contact-us.faq.question-right_5.title')}
                         description={t('contact-us.faq.question-right_5.description')}/>
                <AskItem title={t('contact-us.faq.question-right_6.title')}
                         description={t('contact-us.faq.question-right_6.description')}/>
              </div>
            </div>
          </ShowAnimation>
        </Container>
      </WhiteSection>
    </div>
  );
}