import css from './AskItem.module.scss';
import {Accordion, AccordionBody, AccordionHeader} from '@material-tailwind/react';
import {useAnimate} from 'framer-motion';
import {useState} from 'react';
import classNames from 'classnames';

function Icon({id, open}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
    </svg>
  );
}

export default function AskItem({title, description}) {
  const [scope, animate] = useAnimate();
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => {
    if (open === 1) {
      animate(scope.current, {color: 'rgb(14, 14, 14)'}, {duration: .5});
    } else {
      animate(scope.current, {color: 'rgb(124, 83, 211)'}, {duration: .5});
    }
    setOpen(open === value ? 0 : value);
  }

  return (
    <Accordion open={open === 1} icon={<Icon id={1} open={open}/>}>
      <AccordionHeader onClick={() => handleOpen(1)}>
        <div ref={scope} className={css.title} style={{color: 'rgb(14, 14, 14)'}}>
          {title}
        </div>
      </AccordionHeader>
      <AccordionBody>
        <p className={classNames(css.p, css.accordionListContent)}>
          {description}
        </p>
      </AccordionBody>
    </Accordion>
  );
}