import Footer from '../banners/footer/Footer';
import Header from '../header/Header';
import Container from '../container/Container';
import TitleWrapper from '../titles/wrapper/TitleWrapper';
import H1title from '../titles/H1title';
import Description from '../description/Description';
import BannerTopSection from '../bannerTopSection/BannerTopSection';
import GetInTouch from '../banners/getInTouch/GetInTouch';
import FrequentlyAsk from '../banners/frequentlyAsk/FrequentlyAsk';
import ContactUsInnerSection from '../sections/contactUsInnerSection/ContactUsInnerSection';
import ScrollToTop from '../helpers/ScrollTop';
import {useRef} from 'react';
import ShowAnimation from '../helpers/ShowAnimation';
import {useTranslation} from 'react-i18next';
import World from '../world/World';

export default function ContactUsPage() {
  const {t} = useTranslation();
  const ref = useRef();
  const formRef = useRef();

  return (
    <>
      <ScrollToTop/>
      <Header/>
      <BannerTopSection>
        <Container>
          <TitleWrapper ref={ref}>
            <ShowAnimation ref={ref} direction={'v'} order={1}>
              <H1title isWhite={true}>{t('contact-us.title')}</H1title>
            </ShowAnimation>
            <ShowAnimation ref={ref} direction={'v'} order={2}>
              <Description>
                {t('contact-us.description')}
              </Description>
            </ShowAnimation>
          </TitleWrapper>
        </Container>
      </BannerTopSection>
      <div ref={formRef}>
        <GetInTouch/>
      </div>
      <FrequentlyAsk/>
      <World/>
      <ContactUsInnerSection onClick={() => {
        formRef.current.scrollIntoView({
          behavior: 'smooth'
        });
      }}/>
      <Footer/>
    </>
  );
}