import css from './Footer.module.scss';
import Container from '../../container/Container';
import classNames from 'classnames';
import H6title from '../../titles/H6title';
import {useTranslation} from 'react-i18next';

export default function Footer() {
  const {t} = useTranslation();

  return (
    <div className={css.footer}>
      <Container>
        <div className={css.footerWrapper}>
          <div>
            <a href={'/'}>
              <img loading={'lazy'} alt={'header-logo'} src="/footer_logo_svg.svg"/> {/*android-chrome-512x512.png*/}
            </a>
            <p className={classNames(css.p, css.footerBrandDescription)}>
              {t('footer.description')}
            </p>
          </div>
          <div className={css.wrapLinkColumns}>
            <div className={css.footerLinksWrapper}>
              <div className={css.footerTitle}>
                <H6title isWhite={true}><a href={'/#/contact-us'}>{t('buttons.contact-now')}</a></H6title>
              </div>
              <div className={css.contactUsInfoWrapper}>
                <div className={classNames(css.smallText, css.light)}>Email</div>
                <a href={'mailto:tech.architect.consultancy@gmail.com'} className={classNames(css.a, css.footerContactLink)}>tech.architect.consultancy@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}