import css from './SolutionItem.module.scss';
import classNames from 'classnames';
import H5title from '../../../titles/H5title';

export default function SolutionItem({url, title, description, cn}) {
  return (
    <div className={classNames(css.solutionsItem, cn)}>
      <div className={css.solutionItemInnerWrapper}>
        <img loading={'lazy'} alt={'solution'} className={css.solutionImage} src={url}/>
        <a className={classNames(css.a, css.inlineBlock)}>
          <H5title cn={css.solutionTitle} isWhite={true}>{title}</H5title>
        </a>
        <p className={classNames(css.p, css.paragraphLight, css.textCenter)}>
          {description}
        </p>
      </div>
    </div>
  );
}