import {forwardRef} from 'react';
import {useInView} from 'framer-motion';

const ShowAnimation = forwardRef(({children, direction, order, fullWidth}, ref) => {
  const isInView = useInView(ref, {once: true, amount: 'some'});

  const directions = {
    'hl': 'translateX(-200px)',
    'hr': 'translateX(200px)',
    'v': 'translateY(200px)'
  }

  return (
    <div
      style={{
        zIndex: 20,
        width: fullWidth ? '100%' : 'initial',
        position: 'relative',
        transform: isInView ? 'none' : `scale(.8) ${directions[direction] || ''}`,
        opacity: isInView ? 1 : 0,
        transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${0.2 * order}s`
      }}>
      {children}
    </div>
  );
});

export default ShowAnimation;